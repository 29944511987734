<template>
  <app-container class="login">
    <h1>Entre com sua rede social</h1>
    <button @click="_login" class="btn">
      <img
        src="https://facebookbrand.com/wp-content/uploads/2019/10/flogo_RGB_HEX-BRC-Site-250.png?w=28&h=28"
        alt="Instagram"
        style="margin-right: 8px"
      />

      <span>Entrar com o Facebook</span>
    </button>

    <!-- <button @click="authorize" class="btn">
      <img
        src="https://facebookbrand.com/wp-content/uploads/2021/03/glyph-logo_May2016.png?w=24"
        alt="Instagram"
        style="margin-right: 8px"
      />

      <span>Entrar com o Instagram</span>
    </button> -->
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer'
import api from '@/services/api'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    AppContainer
  },
  data() {
    return {
      url: '#'
    }
  },
  created() {
    const { code } = this.$route.params
    if (code) {
      api
        .get(`/authorize/${code}`)
        .then(({ data }) => {
          if (data.userId) {
            this.login(data)
              .then(() => this.$router.push('/dashboard'))
              .catch(console.error)
          }
        })
        .catch(console.error)
        .finally(() =>
          history.replaceState(
            null,
            document.title,
            window.location.href.replace(/(.*\/login).*/, '$1')
          )
        )
    }

    api
      .get('/url/login')
      .then(({ data: { url } }) => {
        this.url = url
      })
      .catch(console.error)

    this.loginFB()
  },
  watch: {
    status: {
      handler(value) {
        if (value.loggedIn) {
          this.$router.push('/dashboard')
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('auth', ['status'])
  },
  methods: {
    ...mapActions('auth', ['login']),
    authorize() {
      window.location.href = this.url
    },
    loginFB() {
      window.FB.getLoginStatus(
        response => this.statusChangeCallback(response),
        true
      )
    },
    $login() {
      window.FB.api('/me', response => {
        const { id: userId, name: userName } = response

        this.login({
          userId,
          userName
        }).catch(console.error)
      })
    },
    statusChangeCallback(response) {
      if (response.status === 'connected') {
        this.$login()
      }
    },
    _login() {
      window.FB.getLoginStatus(status => {
        if (status === 'connected') {
          this.$login()
          return
        }

        window.FB.login(this.statusChangeCallback, {
          scope: 'public_profile,email'
        })
      }, true)
    }
  }
}
</script>

<style scoped lang="sass">
.login
  max-width: 480px
  margin: 0 auto
  text-align: center;
.btn
  display: flex
  align-items: center
  width: 210px
  padding: 8px 12px
  border-radius: 4px
  border: 1px solid #ddd
  background-color: #fff
  margin: 0 auto
  font-weight: bold
  font-size: 16px
  width: 240px
</style>
